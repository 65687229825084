.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 50%;
  background-color: #0074D9; /* Color de fondo */
  color: #ffffff; /* Color del texto */
  font-weight: bold; /* Texto en negrita */
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra */
}

.my-button:hover {
  background-color: #0056b3;
}

/* ButtonGridContainer.css */
.button-grid-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 3 columnas en el grid */
  gap: 10px; /* Espacio entre los botones */
}

.grid-button {
  padding: 10px;
  background-color: #0074D9;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.grid-button:hover {
  background-color: #0056b3;
}

/* Table.css */
.custom-table {
  border-collapse: collapse; /* Combina los bordes de las celdas */
  width: 100%;
  font-size: 24px;
}

.custom-table th, .custom-table td {
  border: 1px solid #c3ccd2; /* Bordes de las celdas */
  padding: 2px; 
  text-align: center;
  
}

.custom-table th {
  background-color: #c3ccd2; /* Color de fondo para los encabezados */
}

/* ButtonGrid.css */
.button-grid {
  display: grid; /* O utiliza 'flex' según tus necesidades */
  grid-template-columns: repeat(4, 1fr); /* 3 columnas, ajusta según tu diseño */
  grid-template-rows: repeat(8, 1fr); /* 3 columnas, ajusta según tu diseño */
  gap: 20px; /* Espacio entre botones */
}

.button-grid button {
  /* Estilos para los botones (tamaño, colores, etc.) */
  width: 60px;
  height: 60px;
  background-color: #66971d; /* Color de la bola de billar */
  border-radius: 50%; /* Forma circular */
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

/* ButtonGrid.css */
.button-vehicle-grid {
  display: grid; /* O utiliza 'flex' según tus necesidades */
  grid-template-columns: repeat(12, 1fr); /* 3 columnas, ajusta según tu diseño */
  gap: 20px; /* Espacio entre botones */
}

.button-vehicle-grid button {
  /* Estilos para los botones (tamaño, colores, etc.) */
  width: 55px;
  height: 55px;
  background-color: #66971d; /* Color de la bola de billar */
  border-radius: 50%; /* Forma circular */
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.item1 {
  grid-column: 1;
  grid-row: 1;
}
.item2 {
  grid-column: 2;
  grid-row: 1;
}
.item3 {
  grid-column: 3;
  grid-row: 1;
}
.item4 {
  grid-column: 4;
  grid-row: 1;
}
.item5 {
  grid-column: 1;
  grid-row: 2;
}
.item6 {
  grid-column: 2;
  grid-row: 2;
}
.item7 {
  grid-column: 3;
  grid-row: 2;
}
.item8 {
  grid-column: 4;
  grid-row: 2;
}
.item9 {
  grid-column: 1;
  grid-row: 3;
}
.item10 {
  grid-column: 2;
  grid-row: 3;
}

.item11 {
  grid-column: 3;
  grid-row: 3;
}
.item12 {
  grid-column: 4;
  grid-row: 3;
}
.item13 {
  grid-column: 1;
  grid-row: 4;
}
.item14 {
  grid-column: 2;
  grid-row: 4;
}
.item15 {
  grid-column: 3;
  grid-row: 4;
}
.item16 {
  grid-column: 4;
  grid-row: 4;
}
.item17 {
  grid-column: 1;
  grid-row: 5;
}
.item18 {
  grid-column: 2;
  grid-row: 5;
}
.item19 {
  grid-column: 3;
  grid-row: 5;
}
.item20 {
  grid-column: 4;
  grid-row: 5;
}

.item21 {
  grid-column: 1;
  grid-row: 6;
}
.item22 {
  grid-column: 2;
  grid-row: 6;
}
.item23 {
  grid-column: 3;
  grid-row: 6;
}
.item24 {
  grid-column: 4;
  grid-row: 6;
}
.item25 {
  grid-column: 1;
  grid-row: 7;
}
.item26 {
  grid-column: 2;
  grid-row: 73;
}
.item27 {
  grid-column: 3;
  grid-row: 73;
}
.item28 {
  grid-column: 4;
  grid-row: 7;
}
.item29 {
  grid-column: 1;
  grid-row: 8;
}
.item30 {
  grid-column: 2;
  grid-row: 8;
}
.item31 {
  grid-column: 3;
  grid-row: 8;
}
.item32 {
  grid-column: 4;
  grid-row: 8;
}

[type=checkbox] {
  width: 2.5rem;
  height: 2.5rem;
  color: dodgerblue;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: background 300ms;
  cursor: pointer;
}


/* Pseudo element for check styling */

[type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #1a1a1b;
}


/* Checked */

[type=checkbox]:checked {
  background-color: currentcolor;
}

[type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}


/* Disabled */

[type=checkbox]:disabled {
  background-color: #CCD3D8;
  opacity: 0.84;
  cursor: not-allowed;
}


/* IE */

[type=checkbox]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

[type=checkbox]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}